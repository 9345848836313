import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconDice5 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-5.tsx";
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx";

export function AccountingContent({ back, args }){

	return (
		<div>
			<_AccountingContent args={args} back={back} />
		</div>
	);
}

class _AccountingContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		export: null,
		items: null
	};

	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/accounting',
			method: 'post',
			data: {
				action: 'content',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.items
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	saveData = function (data, fileName) {
		var a = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";
			var json = JSON.stringify(data),
				blob = new Blob([json], {type: "octet/stream"}),
				url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = fileName;
			a.click();
			window.URL.revokeObjectURL(url);
	};

	manualExport = function(type){
		let that = this;
		ProgressiveRequest({
			url: getServer()+'/api/front/accounting',
			method: 'post',
			data: {
				action: 'export',
				startdate: '25/05/2023',
				enddate: '25/06/2023',
				type: type,
				session: this.context
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					export: data.content
				});

			},
			received: function(){
				console.log('done');
				that.saveData(that.state.export, "export."+type);
			}
		});
	}

	displayContent = function(item){
		this.setState({content: item});
	}
	
	render() {
		let that = this;

		if(this.state.items){
			
			let list = [{
				name: 'Envoi mensuel au cabinet comptable',
				channel: 'email',
				to: 'jaki@lacompta.com',
				period: 'mensuel',
				format: 'pnm'
			}];

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconBook2 class="w-6 h-6" /><span class="ms-2">Les exports comptables</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="card" style="background-color:rgb(235 236 240)">
							<div class="card-body">
								<div class="h3">Vos documents comptables</div>
								<div>L'ensemble des données de vente de vos outils est consolidé et disponible dans les différents formats d'export comtpable.</div>
							</div>
						</div>
					</div>
					<div class="my-4">
						<div class="rounded-3 p-3 bg-danger text-white">
							<div>Certaines correspondances n'ont pas été configurées. Veuillez les renseigner pour avoir accès à vos exports comptables.</div>
							<div class="d-flex align-items-center justify-content-between mt-2">
								<span></span>
								<a type="button" href="/le-referentiel" class="btn btn-light rounded-pill d-flex align-items-center btn-sm ps-3">En savoir plus <IconChevronRight class="w-6 h-6 ms-2" /></a>
							</div>
						</div>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-1">
							<h2 class="h4 d-flex align-items-center mb-0">
								<IconDice5 class="w-6 h-6 me-2" /><span>Export manuel</span>
							</h2>
						</div>
						<div class="card">
							<div class="card-body">
								<div>Définissez votre période à exporter ainsi que le format et c'est parti !</div>
								<div>Attention, pour éviter l'oubli des chiffres d'un service, les données peuvent être exportées à partir d'un moment où un service du lendemain a été reçu.</div>
								<div class="py-2">
									<div class="py-2">
										<div>
											Période
										</div>
										<div>
											<button type="button" class="btn btn-outline-secondary rounded-pill py-0 me-2">{"du 25 avril au 25 mai"}</button>
										</div>
									</div>
									<div class="py-2">
										<div>
											Format
										</div>
										<div>
											<a href="#" onClick={() => this.manualExport("pnm")} type="button" class="btn btn-primary rounded-pill py-0 me-2">Sage PNM</a>
											<button type="button" class="btn btn-primary rounded-pill py-0 me-2">Cegid CGD</button>
											<button type="button" class="btn btn-primary rounded-pill py-0 me-2">Oracle ABC</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-1">
							<h2 class="h4 d-flex align-items-center mb-0">
								<IconDice5 class="w-6 h-6 me-2" /><span>Export automatisé</span>
							</h2>
						</div>
						<div class="card">
							<div class="card-body">
								<div>Planifiez les envois automatiques des données comptables à votre comptable.</div>
								<div>
									<div class="list-group border-0">
										{list.map((item)=>{
											return <div class="list-group-item border-0 rounded-1 list-group-item-action py-2 border-bottom">
												<div class="d-flex align-items-center justify-content-between">
													<div>
														<div class="d-flex align-items-center fs-5 mb-0">
															<span class="">{item.name}</span>
														</div>
														<div>
															<span class="badge rounded-pill bg-light text-dark">{item.channel} : {item.to}</span>
															<span class="badge rounded-pill bg-light text-dark ms-2">{item.period}</span>
															<span class="badge rounded-pill bg-light text-dark ms-2">{item.format}</span>
														</div>
													</div>
													<div class="p-2 rounded-pill bg-light">
														<IconChevronRight class="w-6 h-6" />
													</div>
												</div>
											</div>;
										})}
									</div>
									<div class="d-flex align-items-center justify-content-between mt-2">
										<div></div>
										<div>
											<button type="button" class="btn btn-outline-secondary rounded-pill py-1 px-3 me-2">Ajouter</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);

		}

		return <div>
			<div class="spinner-border text-secondary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	}
}